import QrScanner from 'qr-scanner';

let selectedWord = "";
let audioContext = null;
let currentSource = null;

const trackName = (name) => {
    if (name === 'ஐயம் ?') {
        return `/mp3s/ஐயம்_q.mp3`;
    }
    return `/mp3s/${name}.mp3`;
};

const cachedTracks = {};

const getTrackBuffer = async (name) => {
    if(cachedTracks[name] != null && cachedTracks[name] != undefined) {
        return cachedTracks[name];
    }
    const context = await getContext();
    const resp = await fetch(trackName(name));
    const buf = await resp.arrayBuffer();
    const audioBuf = await context.decodeAudioData(buf);
    cachedTracks[name] = audioBuf;
    return audioBuf;
}

const getContext = () => {
    if(audioContext==null) {
        audioContext = new AudioContext();
    }
    return audioContext;
}

const playAudio = async (name) => {
    const context = getContext();
    const output = document.getElementById('output');
    output.innerText = name;
    const audioBuf = await getTrackBuffer(name);
    const source = context.createBufferSource();
    if(currentSource != null) {
        currentSource.stop();
    }
    currentSource = source;   
    source.buffer = audioBuf;
    source.connect(context.destination);
    source.start();
};

window.addEventListener('DOMContentLoaded', () => {
    const videoElem = document.getElementById('qrcode');
    const output = document.getElementById('output');
    // const playButton = document.getElementById("play-button");
    const qrScanner = new QrScanner(videoElem, async (res) => {
        output.innerText = res
        selectedWord = res
        // playButton.style.display = "inline-block";
        playAudio(selectedWord);
        qrScanner.stop();
        setTimeout(() => {
            qrScanner.start();
        }, 1000);
    });
    // playButton.addEventListener("click", async () => {
    //     // selectedWord = 'அம்மா';
    //     await playAudio(selectedWord);
    // })


    qrScanner.start();
})